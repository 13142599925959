//API URl

export const BASE_URL = `${process.env.REACT_APP_MODEL_API_URL}`;
export const DEVICE_BASE_URL = `${process.env.REACT_APP_DEVICE_API_URL}`;
export const FILE_BASE_URL = `${process.env.REACT_APP_FILE_API_URL}`;
export const KEYCLOAK_BASE_URL = `${process.env.REACT_APP_KEYCLOAK_API_URL}`;
export const AUTH_BASE_URL = `${process.env.REACT_APP_AUTH_API_URL}`;
export const ASSET_SUITE_LOGIN_URL = `${process.env.REACT_APP_SUITE_URL}`;
export const CONTRACT_URL = `${process.env.REACT_APP_CONTRACT_URL}`;

export const THEME_CHANGE_URL = {
  // THEME_CHANGE :AUTH_BASE_URL+"api/v1/attributes/set-theme"
  THEME_CHANGE:
    "https://dtech-api.seqrops.in/oauth/api/v1/attributes/set-theme",
};

export const ASSET_URLS = {
  APP_SUITE_URL: `${process.env.REACT_APP_APP_SUITE_URL}/dashboard`,
  ASSET_MANAGEMENT_URL: `${process.env.REACT_APP_ASSETS_URL}`,
  ASSET_MODELS_URL: `${process.env.REACT_APP_MODELS_URL}`,
  DEVICE_MANAGEMENT_URL: `${process.env.REACT_APP_DEVICE_MANAGEMENT_URL}/#/devices`,
  FIELD_SERVICE_MANAGEMENT_URL: `${process.env.REACT_APP_FSM_URL}`,
  OPERATIONAL_DASHBOARD_URL: `${process.env.REACT_APP_DASHBOARD_URL}`,
  MANAGEMENT_DASHBOARD_URL: `${process.env.REACT_APP_DASHBOARD_URL}`,
  CONTRACT_URL: `${process.env.REACT_APP_CONTRACT_URL}`,
  MONITORING_DASHBOARD_URL: `${process.env.REACT_APP_DASHBOARD_URL}`,
  GRID_MONOTORING_APP: `${process.env.REACT_APP_GRID_MONOTORING_APP}`,
  ANALYTICS_DASHBOARD: `${process.env.REACT_APP_ANALYTICS_DASHBOARD}`,
  OPEN_EMS_APP: `${process.env.REACT_APP_OPEN_EMS_APP}/#/overview`,
  INTEGRATED_SIMULATOR_ENVIRONMENT: `${process.env.REACT_APP_OPEN_INTEGRATED_SIMULATOR_ENVIRONMENT}`, 
};

export const ASSET_AUTH_API = {
  GET_LOGIN_TOKEN: AUTH_BASE_URL + "api/v1/auth/token",
  VERIFY_LOGIN_TOKEN: AUTH_BASE_URL + "api/v1/auth/token/introspect",
  LOGOUT_TOKEN: AUTH_BASE_URL + "api/v1/auth/logout",
};

export const COOKIE_DETAILS = {
  COOKIE_NAME: `${process.env.REACT_APP_COOKIE_PREFIX}_user_session_id`,
  COOKIE_DOMAIN: `${process.env.REACT_APP_COOKIE_DOMAIN}`,
};

export const IFRAME_ID = {
  SUITE_IFRAME_ID: "ifr_suite",
  MODELS_IFRAME_ID: "ifr_models",
  ASSETS_IFRAME_ID: "ifr_assets",
  DEVICE_MANAGEMENET_IFRAME_ID: "ifr_device",
  FSM_IFRAME_ID: "ifr_fsm",
  DASHBOARD_IFRAME_ID: "ifr_dashboard",
};

export const MESSAGES = {
  LOGGED_OUT: "Logged out Successfully",
  SOMETHING_WENT_WRONG: "Something went wrong",
  USER_UNAUTHORIZED: "User unauthorized",
  USERNAME_REQUIRED: "Please enter username",
  PASSWORD_REQUIRED: "Please enter password",
  INVALID_LOGIN_CREDENTIALS: "Invalid login credentials",
};

export const SESSION_TYPE = {
  USER_LOGIN_TYPE: "1",
  USER_LOGOUT_TYPE: "0",
  DOMAIN_PASS_TYPE: "2",
};

export const RESERVED_PROPS = {
  RESERVED_PROPERTY_DELETED: "deleted",
};

export const KEYCLOAK_GRANT_TYPES = {
  PASSWORD: "password",
  CLIENT_CREDENTIALS: "client_credentials",
  REFRESH_TOKENS: "refresh_token",
};

export const KEYCLOAK_CLIENTS = {
  ASSET_SUITE: "asset-suite",
  ASSET_MANAGEMENT: "asset-management",
  ASSET_MODELS: "asset-models",
  DEVICE_MANAGEMENT: "device-management",
  FIELD_SERVICE_MANAGEMENT: "field-service-management",
  OPERATIONAL_DASHBOARD: "operational-dashboard",
  MANAGEMENT_DASHBOARD: "management-dashboard",
  ASSET_CONTRACT: "asset-contract",
  MONITORING_DASHBOARD: "monitoring-dashboard",
  GRID_MONITORING_APP: "grid-monitoring-app",
  ANALYTICS_DASHBOARD: "analytics-dashboard",
  EMS_APP: "ems_app",
};

export const CLEAR_CLIENT_SCOPES = "clear-scopes";

export const KEYCLOAK_CLIENT_SECRETS = {
  ASSET_SUITE: `${process.env.REACT_APP_SUITE_CLIENT_SECRET}`,
};

export const DIRECT_DASHBOARD_ACCESS_USERS = ["shery"];

export const SESSION_KEY = {
  NAVIGATION_STATE: "vdfwfznVgu",
  LOCAL_STORAGE_KEY: "ut",
  REDIRECT_LOCATION_KEY: "rl",
  CLEAR_ALL_TOKEN_DATA_KEY: "cl",
  DOMAIN_KEY: "dmn",
  SUITE_KEY: "su1te",
  MODELS_KEY: "mod3ls",
  ASSETS_KEY: "ass3ts",
  DEVICE_MANAGEMENET_KEY: "d3vice",
  FSM_KEY: "f2m",
  OPERATIONAL_DASHBOARD_KEY: "0pd",
  MANAGEMENT_DASHBOARD_KEY: "Wgmt",
};

export const ERROR_CODES = {
  SCHEMA_DB_NOT_FOUND: "SCHEMA_DB_NOT_FOUND",
};

export const DASHBOARD_TYPE = {
  REDIRECT: "redirectFrom",
};
export const ASSET_REDIRECT_SCOPES = {
  ASSET_SUITE: "asset_suite",
  ASSET_MANAGEMENT: "asset_management",
  ASSET_MODELS: "asset_models",
  DEVICE_MANAGEMENT: "device_management",
  FIELD_SERVICE_MANAGEMENT: "field_service_management",
  OPERATIONAL_DASHBOARD: "operational_dashboard",
  MANAGEMENT_DASHBOARD: "management_dashboard",
  ASSET_CONTRACT: "asset_contract",
  MONITORING_DASHBOARD: "monitoring_dashboard",
  GRID_MONITORING_APP: "grid_monitoring_app",
  ANALYTICS_DASHBOARD: "analytics_dashboard",
};
export const ASSET_SCOPES = [
  "asset-management",
  "asset-models",
  "device-management",
  "asset-suite",
  "field-service-management",
  "operational-dashboard",
  "management-dashboard",
  "asset-contract",
  "monitoring-dashboard",
  "grid-monitoring-app",
  "analytics-dashboard",
  "ems_app",
];
export const ASSET_MANAGEMENT_SCOPE = "asset-management";
export const ASSET_SUITE_SCOPE = "asset-suite";
export const ASSET_MODELS_SCOPE = "asset-models";
export const DEVICE_MANAGEMENET_SCOPE = "device-management";
export const FIELD_SERVICE_MANAGEMENT = "field-service-management";
export const MANAGEMENT_DASHBOARD = "operational-dashboard";
export const OPERATIONAL_DASHBOARD = "management-dashboard";

export const UNAUTHORIZED_LOGIN = "Unauthorized";
